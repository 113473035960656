import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-react-intl';
import { navigate } from 'gatsby';
import contact from '../images/use-3.png';
import { H15 } from './Texts';
import EmptyHeader from './EmptyHeader';

const Success = ({ location, text }) => {
  const intl = useIntl();

  useEffect(() => {
    if (!location.state || !location.state.submitted) {
      navigate('/contact');
    }
  }, [location]);

  return (
    <>
      <EmptyHeader />
      <div className="mx-auto max-w-7xl flex items-start flex-col lg:flex-row">
        <div className="px-4 py-6 lg:pt-40 lg:pl-32 lg:w-7/12 lg:flex-shrink-0">
          <H15 className="mb-3">{intl.formatMessage({ id: 'success.title' })}</H15>
          <p className="mb-6">{intl.formatMessage({ id: text })}</p>
        </div>
        <div className="flex">
          <img src={contact} alt="contact" />
        </div>
      </div>
    </>
  );
};
Success.propTypes = {
  text: PropTypes.string.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      submitted: PropTypes.bool,
    }),
  }).isRequired,

};

export default Success;
